export function Crypt_pass(utl,ps) {
	if(utl.length<1) {
		return(false);
	}
	if(ps.length<1) {
		return(false);
	}else{
		var d=new Date();
		var cd=d.toDateString().split(" ")[2];
		var dps=ps+cd;
		var md5 = require('md5');
		var mdp=md5(dps);
		return mdp;
	}
}
export function fetch_post_form(data) {
	var form_data = new FormData();
	form_data.append("j_data",JSON.stringify(data));
	var post_data={
		method: "POST", // *GET, POST, PUT, DELETE, etc.
		mode: "cors", // no-cors, cors, *same-origin
		cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
		credentials: "same-origin", // include, *same-origin, omit
//		headers: {
//			"Access-Control-Allow-Origin": "*"
//		},
		redirect: "follow", // manual, *follow, error
		referrer: "no-referrer", // no-referrer, *client
		body: form_data, // body data type must match "Content-Type" header
	}
	console.log(post_data);
	return post_data;
}

export function fetch_url_post(url,data,success_handler,fail_handler) {
	var url_base="http://relaccesapi.psiline.ro/";
	url=url_base+url;
	var post_data=fetch_post_form(data);
	fetch(url,post_data).then(res => res.json()).then(
																	(result) => {
																					success_handler(result);
																				},
																	(error) => {
																					fail_handler();
																				}
	)    
}

export function add_to_array(array,new_item,index) {
	if(!array) {
		array=[];
	}
	if(index) {
		array[index]=new_item;
	}else{
		array.push(new_item);
	}
	return array;
}

export function remove_from_array(array,element) {
	if(!array) {
		return [];
	}
	array=array.filter((array_element)=>{				
		if(array_element.id===element.id) {
			return false;
		}else{
			return true;
		}
	});
	return array;
}

export function find_index_in_array(array,element) {
	if(!array) {
		return -1;
	}
	return array.findIndex((array_element)=>array_element.id===element.id);
}

export function replace_element_in_array(array,find_element,replace_element) {
	if(!array) {
		return [];
	}
	var index=find_index_in_array(array,find_element);
	array[index]=replace_element;
	return array;
}

export function clone_object(object) {
	let cloned_object=Object.assign({},object);
	return cloned_object;
}

export function object_is_empty(object) {
	for(var key in object) {
		if(object.hasOwnProperty(key)) {
			return false;
		}
	}
	return true;
}

export function find_element_in_array(array,find_element) {
	if(!array) {
		return {};
	}
	var index=find_index_in_array(array,find_element);
	return array[index];
}

export function array_diff(array_1,array_2) {
	let ret=array_1.filter((element)=>{
		for(var i=0;i<array_2.length;i++) {
//			console.log(array_2[i]);
//			console.log("compar: "+element.id+" cu "+array_2[i].id);
			if(element.id===array_2[i].id) {
//				console.log("gasit");
				return false;
			}
		}
//		console.log("negasit");
		return true;
	});
	return ret;
}