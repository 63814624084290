import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		height: '100vh',
	},
	image: {
		backgroundImage: 'url(https://source.unsplash.com/random)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
        backgroundPosition: 'center',
	},
	paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	form: {
		width: '63%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	avatar: {
		marginTop: "45%",
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

const LoginFormM = (props) => {

	const classes = useStyles();
	
    return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<Grid item xs={12} sm={5} md={5} lg={4} xl={3} component={Paper} elevation={6} square>
				<div className={classes.paper}>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Autentificare
					</Typography>
					<div className={classes.form}>
						<TextField
							fullWidth
							variant="outlined"
							margin="dense"
							required
							name="utl"
							id="utl"
 							label="Utilizator"
							autoComplete="user_name"
							autoFocus
							onChange={evt => props.updateInputValue(evt)}
						/>
						<br/>
						<TextField
							fullWidth
							variant="outlined"
							margin="dense"
							required
							name="ps"
							id="ps"
							label="Parola"
							type="password"
							autoComplete="current-password"
							onChange={evt => props.updateInputValue(evt)}
						/>
						<br/>
						<Button
							fullWidth
							type="submit"
							variant="contained"
							color="primary"
							className={classes.submit}
							onClick={props.auth}
						>
							Autentificare
						</Button>
          			</div>
        		</div>
      		</Grid>
			<Grid item xs={false} sm={7} md={7} lg={8} xl={9} className={classes.image} />
		</Grid>
	);
};

export default LoginFormM;
