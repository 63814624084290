import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Fieldset} from 'primereact/fieldset';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider,DatePicker} from 'material-ui-pickers';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import LinearProgress from '@material-ui/core/LinearProgress';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {fetch_url_post,add_to_array,remove_from_array,find_index_in_array,replace_element_in_array,clone_object} from "../../Sources/js/app.js";

class Programari extends React.Component {
	constructor(props) {
		super(props);
		this.data=new Date().toISOString().slice(0,10).replace('T', ' ');
		let tmp_data=this.data.split("-");
		this.data=tmp_data[0]+"-"+tmp_data[1]+"-"+tmp_data[2];
		console.log(this.data);
		this.reset_data={
			id_serviciu: "",
			id_maseur: "",
			data_programare: this.data,
			ora_programare: "",
			minut_programare: "",
		}
		this.state={
            is_loaded: 0,
			lista_clienti: [],
			message_open: false,
			message_text: "",
			selected_el: undefined,
			data:clone_object(this.reset_data),
			lista_servicii: [],
			is_loaded_lista_maseuri: 0,
			lista_maseuri: [],
			program_maseur: [],
			is_loaded_program_maseur: 0,
			ore_list: [
				{
					label: "07",
					value: "07",
				},
				{
					label: "08",
					value: "08",
				},				
				{
					label: "07",
					value: "07",
				},
				{
					label: "09",
					value: "09",
				},				
				{
					label: "10",
					value: "10",
				},
				{
					label: "11",
					value: "11",
				},				
				{
					label: "12",
					value: "12",
				},
				{
					label: "13",
					value: "13",
				},				
				{
					label: "14",
					value: "14",
				},
				{
					label: "15",
					value: "15",
				},				
				{
					label: "16",
					value: "16",
				},
				{
					label: "17",
					value: "17",
				},				
				{
					label: "18",
					value: "18",
				},
				{
					label: "19",
					value: "19",
				},				
				{
					label: "20",
					value: "20",
				},
			],
			minute_list: [
				{
					label: "00",
					value: "00",
				},
				{
					label: "05",
					value: "05",
				},				
				{
					label: "10",
					value: "10",
				},
				{
					label: "15",
					value: "15",
				},				
				{
					label: "20",
					value: "20",
				},
				{
					label: "25",
					value: "25",
				},				
				{
					label: "30",
					value: "30",
				},
				{
					label: "35",
					value: "35",
				},				
				{
					label: "40",
					value: "40",
				},
				{
					label: "45",
					value: "45",
				},				
				{
					label: "50",
					value: "50",
				},
				{
					label: "55",
					value: "55",
				},				
			],
        };
        this.oper="";
		this.modif_el={};
		this.url="programari.asp?session-id="+this.props.sess.sessid;
		this.success_handler=this.success_handler.bind(this);
		this.fail_handler=this.fail_handler.bind(this);
		this.updateInputValue=this.updateInputValue.bind(this);
		this.message=this.message.bind(this);	
		this.close_message=this.close_message.bind(this);
		this.selectare=this.selectare.bind(this);
		this.operatiuni=this.operatiuni.bind(this);
		this.salveaza_date=this.salveaza_date.bind(this);
		this.sterge_date=this.sterge_date.bind(this);
		this.reseteaza=this.reseteaza.bind(this);
		this.calendar_maseuri=this.calendar_maseuri.bind(this);
		this.program_maseuri=this.program_maseuri.bind(this);
		this.lista_maseuri=this.lista_maseuri.bind(this);
		this.get_lista_maseuri=this.get_lista_maseuri.bind(this);
		this.buton_adaugare=this.buton_adaugare.bind(this);
	}
		
	success_handler(result) {
		if(this.oper==="lista_clienti") {
			this.oper="";
			this.setState({
				is_loaded: 1,
				lista_clienti: result.data.lista_clienti,
				lista_servicii: result.data.lista_servicii,				
			});
		}
		if(this.oper==="lista_maseuri") {
			this.oper="";
			this.setState({
				is_loaded_lista_maseuri: 1,
				lista_maseuri: result.data.lista_maseuri,
			});
		}
		if(this.oper==="program_maseur") {
			this.oper="";
			this.setState({
				is_loaded_program_maseur: 1,
				program_maseur: result.data.program_maseur,
			});
		}
		if(this.oper==="salveaza_adaugare") {
			this.oper="";
			if(result.ok===1) {
				this.setState({
					message_open: true,
					message_text: "Programarea a fost adaugata cu succes!",
				});
				this.get_program_maseur(this.state.data.id_maseur,this.state.data.data_programare);
			}else{
				this.setState({
					is_loaded: 1,
					message_open: true,
					message_text: "A intervenit o eroare in comunicatia cu server-ul. Va rugam sa reincercati mai tarziu!",
				});
			}
		}
	}

	fail_handler() {
		this.setState({
			result: null,
			is_loaded: 1,
			message_open: true,
			message_text: "A intervenit o eroare de comunicatie! Va rugam sa incercati mai tarziu!",
		});
	}

    componentDidMount() {
		this.oper="lista_clienti";
		var data={
			oper: this.oper,
		};
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);
	}
	
	updateInputValue(evt) {
		let tmp=this.state.data;
		if(evt.target.name==="id_serviciu") {
			if(evt.target.value!=="") {
				this.get_lista_maseuri(evt.target.value);
			}
		}
		if(evt.target.name==="id_maseur") {
			if(evt.target.value!=="") {
				this.get_program_maseur(evt.target.value,this.state.data.data_programare);
			}
		}
		tmp[evt.target.name]=evt.target.value.toUpperCase();
		this.setState({
			data: tmp,
		});
	}

	updateInputValueData(date) {
		let year=date.getFullYear();
		let month=date.getMonth()+1;
		let day=date.getDate();
		if(month<10) {
			month="0"+month;
		}
		if(day<10) {
			day="0"+day;
		}
		let tmp=this.state.data;
		tmp.data_programare=year+"-"+month+"-"+day;
		this.get_program_maseur(this.state.data.id_maseur,tmp.data_programare);
		this.setState({
			data: tmp,
		});
	}

    salveaza_date(tip) {
		if((this.state.data.id_serviciu==="")||(this.state.data.id_maseur==="")||(this.state.data.data_programare==="")) {
			this.setState({
				result: null,
				is_loaded: 1,
				message_open: true,
				message_text: "Completati toate datele!",
			});			
			return false;
		}
		this.oper="salveaza_adaugare";
		var data={
			oper: this.oper,
			data: this.state.data,
		};
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);			
	}

	selectare(evt) {
		let tmp=this.state.data;
		tmp.id_client=evt.value.id;
		this.setState({
			data: tmp,
			selected_el: evt.value,
		});
	}

	sterge_date() {

	}

	reseteaza() {
		this.setState({
			selected_el: undefined,
			data: clone_object(this.reset_data),
		});
	}

	get_lista_maseuri(id_serviciu) {
		this.oper="lista_maseuri";
		var data={
			oper: this.oper,
			id_serviciu: id_serviciu,
		};
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);
	}

	get_program_maseur(id_maseur,data) {
		this.oper="program_maseur";
		var data={
			oper: this.oper,
			id_maseur: id_maseur,
			data: data,
		};
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);
	}

	message(content) {
		return(
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				open={this.state.message_open}
				autoHideDuration={3000}
				onClose={this.close_message}
				ContentProps={{
					'aria-describedby': 'message-id',
				}}
				message={<span id="message-id">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.message_text}</span>}
				action={[
					<IconButton
						key="close"
						aria-label="Close"
						color="inherit"
						onClick={this.close_message}
					>
					<CloseIcon />
					</IconButton>,
				]}
			/>
		);
	}

	close_message(event,reason) {
		if(reason==="clickaway") {
			return;
		}
		this.setState({
			message_open: false,
			message_text: "",
		});
	}

	lista_maseuri() {
		if((this.state.data.id_serviciu!=="")&&(this.state.is_loaded_lista_maseuri===1)) {
			return(
				<TextField
					variant="outlined"
					margin="dense"
					name="id_maseur"
					id="id_maseur"
					select
					label="Selectati un maseur"
					fullWidth
					SelectProps={{
						native: true,
					}}
					value={this.state.data.id_maseur}
					onChange={evt => this.updateInputValue(evt)}
				>
					<option/>
					{this.state.lista_maseuri.map(option => (
						<option key={option.id} value={option.id}>
							{option.nume}
						</option>
					))}
				</TextField>
			);
		}
	}

	calendar_maseuri() {
		if((this.state.data.id_serviciu!=="")&&(this.state.data.id_maseur!=="")) {
			return(
				<React.Fragment>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<DatePicker
							name="data_programare"  
							id="data_programare"
							clearable
							variant="outlined"
							margin="dense"
							format="dd-MM-yyyy" 
							label="Data"
							style={{"width":"144px"}}
							value={this.state.data.data_programare}
					  			onChange={date => this.updateInputValueData(date)}
						/>
					</MuiPickersUtilsProvider>
					<TextField
						variant="outlined"
						margin="dense"
						name="ora_programare"
						id="ora_programare"
						select
						label="Ora"
						style={{"marginLeft":"9px","marginRight":"6px","width":"72px"}}
						SelectProps={{
							native: true,
						}}
						value={this.state.data.ora_programare}
						onChange={evt => this.updateInputValue(evt)}
					>
						<option/>
						{this.state.ore_list.map((option,index) => (
							<option key={index} value={option.value}>
								{option.label}
							</option>
						))}
					</TextField>
					<TextField
						variant="outlined"
						margin="dense"
						name="minut_programare"
						id="minut_programare"
						select
						label="Minut"
						style={{"width":"81px"}}
						SelectProps={{
							native: true,
						}}
						value={this.state.data.minut_programare}
						onChange={evt => this.updateInputValue(evt)}
					>
						<option/>
						{this.state.minute_list.map(option => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</TextField>
				</React.Fragment>
			);
		}
	}

	program_maseuri() {
		if((this.state.data.id_serviciu!=="")&&(this.state.data.id_maseur!=="")&&(this.state.is_loaded_program_maseur===1)&&(this.state.program_maseur)) {
			return(
				<div style={{"marginTop":"6px"}}>
					{this.state.program_maseur.map((programare,index) => (
						<Chip
							key={programare.id}
							label={programare.nume_client+" "+programare.nume_serviciu+" "+programare.data_programarii+" ("+programare.durata+" minute)"}
							color="primary"
							style={{"float":"left","margin":"3px","padding":"12px"}}
//							onDelete={(evt)=>{this.sterge_serviciu_inclus(evt,serviciu)}}
					  	/>
					))}
				</div>
			);
		}
	}

	buton_adaugare() {
		if((this.state.data.id_serviciu!=="")&&(this.state.data.id_maseur!=="")&&(this.state.is_loaded_program_maseur===1)) {
			return(
				<Button onClick={(evt)=>{this.salveaza_date()}} color="primary" variant="contained" style={{"marginTop":"9px","width":"150px","marginRight":"9px"}}>
					<AddCircleOutlineIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
					Adauga
				</Button>
			);
		}else{
			return(null);
		}
	}

	operatiuni() {
		if(this.state.selected_el!==undefined) {
			return(
				<React.Fragment>
						<Grid container spacing={1}>
							<Grid item xs={3}>
								<TextField
									variant="outlined"
									margin="dense"
									name="id_serviciu"
									id="id_serviciu"
									select
									label="Selectati un serviciu"
									fullWidth
									SelectProps={{
										native: true,
									}}
									value={this.state.data.id_serviciu}
									onChange={evt => this.updateInputValue(evt)}
								>
									<option/>
									{this.state.lista_servicii.map(option => (
										<option key={option.id} value={option.id}>
											{option.nume}
										</option>
									))}
								</TextField>
							</Grid>
							<Grid item xs={3}>
								{this.lista_maseuri()}
							</Grid>
							<Grid item xs={4}>
								{this.calendar_maseuri()}
							</Grid>
							<Grid item xs={2}>
								{this.buton_adaugare()}
							</Grid>
						</Grid>				
						{this.program_maseuri()}
				</React.Fragment>
			);
		}else{
			return(
				<p style={{"width":"333px","margin":"0 auto","marginTop":"75px"}}>Selectati un client din lista pentru programare.</p>
			);
		}
	}

	render() {
		if(this.state.is_loaded===1) {
			return(
				<div>
					<Fieldset legend="Lista clienti" name="lista_items_3" id="lista_items_3" className="text_center" style={{"padding":"12px","margin":"0px","minHeight":"84%"}}>
						<DataTable ref={(el) => this.dt = el} 
							value={this.state.lista_clienti}
							scrollable={true}
							paginator={true}
							rows={12}
							selectionMode="single"
							selection={this.state.selected_el}
							onSelectionChange={(e)=>{this.selectare(e)}}
						>
							<Column field="nume" header="Nume" style={{"width":"21%"}} filter={true} filterMatchMode="contains"/>
							<Column field="telefon" header="Telefon" style={{"width":"12%"}} filter={true} filterMatchMode="contains"/>
							<Column field="email" header="Email" style={{"width":"18%"}}/>
							<Column field="observatii" header="Observatii" style={{"width":"36%"}} filter={true} filterMatchMode="contains"/>
							<Column field="data_introducerii" header="Data creare" style={{"width":"12%"}}/>							
						</DataTable>
					</Fieldset>
					<Fieldset legend="Operatiuni programari" className="cautare_client" style={{"padding":"12px","margin":"0px","height":"300px","paddingTop":"3px"}}>
						{this.operatiuni()}
					</Fieldset>
					{this.message()}
				</div>
			)			
		}else{
			return(<LinearProgress/>);
		}
	}
}

export default Programari;
