import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import AppsIcon from '@material-ui/icons/Apps';
import PersonIcon from '@material-ui/icons/Person';
import PanToolIcon from '@material-ui/icons/PanTool';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Button from '@material-ui/core/Button';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import ListItemText from '@material-ui/core/ListItemText';
import MainPage from './MainPage';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	appBar: {
    	zIndex: theme.zIndex.drawer + 1,
    	transition: theme.transitions.create(['width', 'margin'], {
																	easing: theme.transitions.easing.sharp,
																	duration: theme.transitions.duration.leavingScreen,
																}
											),
  	},
	appBarShift: {
		marginLeft: drawerWidth,
    				width: `calc(100% - ${drawerWidth}px)`,
    				transition: theme.transitions.create(['width', 'margin'], {
      																			easing: theme.transitions.easing.sharp,
      																			duration: theme.transitions.duration.enteringScreen,
																			}
														),
	},
	menuButton: {
		marginRight: 36,
	},
	menuButtonHidden: {
		display: 'none',
	},
	title: {
		flexGrow: 0,
	},
	flexg: {
		flexGrow: 1,
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
														easing: theme.transitions.easing.sharp,
														duration: theme.transitions.duration.enteringScreen,
													}
											),
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
														easing: theme.transitions.easing.sharp,
														duration: theme.transitions.duration.leavingScreen,
													}
											),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
										width: theme.spacing(9),
									},
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
	},
	container: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(0),
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	fixedHeight: {
    	height: 240,
	},
	fab: {
/*		margin: theme.spacing(1), */
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
}));

export default function Dashboard(props) {
	const classes = useStyles();
	
	const [open, setOpen] = React.useState(true);
	
	const handleDrawerOpen = () => {
    	setOpen(true);
	};
	
	const handleDrawerClose = () => {
    	setOpen(false);
	};
	
	const [open_serviciu, setOpen_serviciu] = React.useState(false);
	
	const [serviciu_curent, set_serviciu_curent] = React.useState("");
	
	const sectiuni=(what)=>{
		setOpen_serviciu(true);
		set_serviciu_curent(what);
	}

//	const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

	return (
			<div className={classes.root}>
				<CssBaseline />
				<AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
					<Toolbar className={classes.toolbar}>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="Open drawer"
							onClick={handleDrawerOpen}
							className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
						>
            			<MenuIcon />
						</IconButton>
							<Typography variant="subtitle1" color="inherit" noWrap className={classes.title}>
								Relacces administrare	
							</Typography>
							<Button color="inherit" style={{"position":"absolute","right":"3px"}} onClick={(ev)=>props.logout(ev)}>
								<PowerSettingsNewIcon className="icon_button"/>
								Logout
							</Button>
        			</Toolbar>
      			</AppBar>
      			<Drawer
        			variant="permanent"
					classes={{
						paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
					}}
        			open={open}
      			>
        			<div className={classes.toolbarIcon}>
						<Typography variant="h6" color="inherit" noWrap style={{"marginRight":"18%"}}>
							<span>Meniu</span>
						</Typography>						
          				<IconButton onClick={handleDrawerClose}>
            				<ChevronLeftIcon />
          				</IconButton>
        			</div>
        			<Divider/>
        			<List>
						<div>
        					<ListItem button onClick={(ev)=>sectiuni("servicii")}>
            					<ListItemIcon>
                					<AppsIcon />
            					</ListItemIcon>
            					<ListItemText primary="Servicii" />
        					</ListItem>
        					<ListItem button onClick={(ev)=>sectiuni("utilizatori")}>
            					<ListItemIcon>
                					<PersonIcon />
            					</ListItemIcon>
            					<ListItemText primary="Utilizatori" />
        					</ListItem>
        					<ListItem button onClick={(ev)=>sectiuni("maseuri")}>
            					<ListItemIcon>
                					<PanToolIcon />
            					</ListItemIcon>
            					<ListItemText primary="Maseuri" />
        					</ListItem>
						</div>
					</List>
					<Divider/>
					<List>
						<div>
        					<ListItem button onClick={(ev)=>sectiuni("clienti")}>
            					<ListItemIcon>
                					<PeopleOutlineIcon />
            					</ListItemIcon>
            					<ListItemText primary="Clienti" />
        					</ListItem>
        					<ListItem button onClick={(ev)=>sectiuni("programari")}>
            					<ListItemIcon>
                					<AlarmAddIcon />
            					</ListItemIcon>
            					<ListItemText primary="Programare noua" />
        					</ListItem>
    					</div>
					</List>
					<Divider/>
					<List>
						<div>
							<ListItem button onClick={(ev)=>sectiuni("raport_incasari")}>
            					<ListItemIcon>
                					<VerticalSplitIcon />
            					</ListItemIcon>
            					<ListItemText primary="Raport incasari" />
        					</ListItem>
        					<ListItem button onClick={(ev)=>sectiuni("raport_programari")}>
            					<ListItemIcon>
                					<AccessTimeIcon />
            					</ListItemIcon>
            					<ListItemText primary="Raport programari" />
        					</ListItem>
        					<ListItem button onClick={(ev)=>sectiuni("raport_zi_nastere")}>
            					<ListItemIcon>
                					<PeopleOutlineIcon />
            					</ListItemIcon>
            					<ListItemText primary="Raport zile nastere" />
        					</ListItem>
						</div>
					</List>
        			<Divider/>
					<List>
						<div>
							<ListItem button onClick={(ev)=>props.logout(ev)}>
            					<ListItemIcon>
                					<PowerSettingsNewIcon />
            					</ListItemIcon>
            					<ListItemText primary="Logout" />
        					</ListItem>
						</div>
					</List>
        			<Divider/>
      			</Drawer>
      			<main className={classes.content}>
        			<div className={classes.appBarSpacer} />
					<Container className={classes.container}>
						<MainPage sess={props.sess} logout={props.logout} open_serviciu={open_serviciu} serviciu_curent={serviciu_curent}/>
        			</Container>
      			</main>
    		</div>
  	);
}