import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Fieldset} from 'primereact/fieldset';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import LinearProgress from '@material-ui/core/LinearProgress';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {fetch_url_post,add_to_array,remove_from_array,find_index_in_array,replace_element_in_array,clone_object} from "../../Sources/js/app.js";

class RaportZiNastere extends React.Component {
	constructor(props) {
		super(props);
		this.data=new Date().toISOString().slice(0,10).replace('T', ' ');
		let tmp_data=this.data.split("-");
		this.data=tmp_data[2]+"-"+tmp_data[1]+"-"+tmp_data[0];
		this.reset_data={
			id: 0,
			nume: "",
			telefon: "",
			email: "",			
			observatii: "",
			data_nasterii: "",
			data_introducerii: this.data,
		}
		this.state={
            is_loaded: 0,
			lista: [],
			message_open: false,
			message_text: "",
			selected_el: undefined,
			data:clone_object(this.reset_data),
			dupa_modificare: 0,
			dialog_programari_open: false,
			lista_programari_client: [],
			is_loaded_lista_programari: 0,
			selected_el_programare: undefined,
        };
        this.oper="";
		this.modif_el={};
		this.url="clienti.asp?session-id="+this.props.sess.sessid;
		this.success_handler=this.success_handler.bind(this);
		this.fail_handler=this.fail_handler.bind(this);
		this.updateInputValue=this.updateInputValue.bind(this);
		this.message=this.message.bind(this);	
		this.close_message=this.close_message.bind(this);
		this.selectare=this.selectare.bind(this);
		this.operatiuni=this.operatiuni.bind(this);
		this.salveaza_date=this.salveaza_date.bind(this);
		this.sterge_date=this.sterge_date.bind(this);
		this.reseteaza=this.reseteaza.bind(this);
		this.lista_programari=this.lista_programari.bind(this);
		this.dialog_programari=this.dialog_programari.bind(this);
		this.selectare_programare=this.selectare_programare.bind(this);
		this.operatiuni_programare=this.operatiuni_programare.bind(this);
		this.sterge_programare=this.sterge_programare.bind(this);
		this.trimite_sms=this.trimite_sms.bind(this);
		this.trimite_email=this.trimite_email.bind(this);
	}
		
	success_handler(result) {
		if(this.oper==="lista_nastere") {
			this.oper="";
			this.setState({
				is_loaded: 1,
				lista: result.data.lista,
			});
		}
		if(this.oper==="salveaza_adaugare") {
			this.oper="";
			if(result.ok===1) {
				let new_item=this.state.data;
				new_item.id=result.new_id;
				this.setState({
					is_loaded: 1,
					new_id: result.new_id,
					lista: add_to_array(this.state.lista,new_item),
					data: clone_object(this.reset_data),
					message_open: true,
					message_text: "Clientul a fost adaugat cu succes!",
				});
			}else{
				this.setState({
					is_loaded: 1,
					message_open: true,
					message_text: "A intervenit o eroare in comunicatia cu server-ul. Va rugam sa reincercati mai tarziu!",
				});
			}
		}
		if(this.oper==="salveaza_modificare") {
			this.oper="";
			if(result.ok===1) {
				this.setState({
					is_loaded: 1,
					lista: replace_element_in_array(this.state.lista,this.state.selected_el,this.state.data),
					data: clone_object(this.reset_data),
					dupa_modificare: 1,
					selected_el: this.state.lista[find_index_in_array(this.state.lista,this.state.selected_el)],
					message_open: true,
					message_text: "Clientul a fost modificat cu succes!",
				});
			}else{
				this.setState({
					is_loaded: 1,
					message_open: true,
					message_text: "A intervenit o eroare in comunicatia cu server-ul. Va rugam sa reincercati mai tarziu!",
				});
			}
		}
		if(this.oper==="salveaza_stergere") {
			this.oper="";
			if(result.ok===1) {
				this.setState({
					is_loaded: 1,
					data: clone_object(this.reset_data),
					lista: remove_from_array(this.state.lista,this.state.selected_el),
					selected_el: undefined,
					message_open: true,
					message_text: "Clientul a fost sters cu succes!",
				});
			}else{
				this.setState({
					is_loaded: 1,
					message_open: true,
					message_text: "A intervenit o eroare in comunicatia cu server-ul. Va rugam sa reincercati mai tarziu!",
				});
			}
		}
		if(this.oper==="lista_programari_client") {
			this.oper="";
			this.setState({
				is_loaded_lista_programari: 1,
				lista_programari_client: result.data.lista_programari_client,
				dialog_programari_open: true,
			});
		}
		if(this.oper==="salveaza_stergere_programare") {
			this.oper="";
			this.setState({
				is_loaded: 1,
				lista: remove_from_array(this.state.lista_programari_client,this.state.selected_el_programare),
				selected_el_programare: undefined,
				message_open: true,
				message_text: "Programarea a fost stearsa cu succes!",
			});
		}
		if(this.oper==="trimite_sms") {
			this.oper="";
			if(result.ok===1) {
				this.setState({
					message_open: true,
					message_text: "Mesajul a fost trimis cu succes!",
				});
			}else{
				this.setState({
					message_open: true,
					message_text: result.error,
				});
			}
		}
		if(this.oper==="trimite_email") {
			this.oper="";
			if(result.ok===1) {
				this.setState({
					message_open: true,
					message_text: "Mesajul a fost trimis cu succes!",
				});
			}else{
				this.setState({
					message_open: true,
					message_text: result.error,
				});
			}
		}
	}

	fail_handler() {
		this.setState({
			result: null,
			is_loaded: 1,
			message_open: true,
			message_text: "A intervenit o eroare de comunicatie! Va rugam sa incercati mai tarziu!",
		});
	}

    componentDidMount() {
		this.oper="lista_nastere";
		var data={
			oper: this.oper,
		};
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);
	}
	
	updateInputValue(evt) {
		let tmp=this.state.data;
		tmp[evt.target.name]=evt.target.value.toUpperCase();
		if(evt.target.name==="data_nasterii") {

		}
		this.setState({
			data: tmp,
		});
	}

    salveaza_date(tip) {
		if(this.state.data.nume==="") {
			this.setState({
				message_open: true,
				message_text: "Completati campul nume client!",				
			});
			return false;
		}
//		if(this.state.data.data_nasterii.indexOf(".")===-1) {
//			this.setState({
//				message_open: true,
//				message_text: "Data nasterii are formatul zz-ll-aaaa. Va rugam modificati!",
//			});				
//			return false;
//		}
//		if(this.state.data.data_nasterii.indexOf("-")===-1) {
//			this.setState({
//				message_open: true,
//				message_text: "Data nasterii are formatul zz-ll-aaaa. Va rugam modificati!",
//			});				
//			return false;
//		}
		if(tip==="adaugare") {
			this.oper="salveaza_adaugare";
		}else{
			this.oper="salveaza_modificare";
		}
		var data={
			oper: this.oper,
			data: this.state.data,
		};
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);
	}

	selectare(evt) {
		this.modif_el=clone_object(evt.value);
		this.setState({
			data: this.modif_el,
			selected_el: evt.value,
			dupa_modificare: 0,
		});
	}

	selectare_programare(evt) {
		this.modif_el_programare=clone_object(evt.value);
		this.setState({
			selected_el_programare: evt.value,
		});
	}

	sterge_date() {
		if(window.confirm("Sigur doriti stergerea? Operatiunea este ireversibila!")) {
			this.oper="salveaza_stergere";
			var data={
				oper: this.oper,
				data: this.state.data,
			};
			fetch_url_post(this.url,data,this.success_handler,this.fail_handler);		
		}
	}

	reseteaza() {
		this.setState({
			selected_el: undefined,
			data: clone_object(this.reset_data),
		});
	}

	lista_programari() {
		let url_programari="programari.asp?session-id="+this.props.sess.sessid;
		this.oper="lista_programari_client";
		var data={
			oper: this.oper,
			id_client: this.state.selected_el.id,
			data: data,
		};
		fetch_url_post(url_programari,data,this.success_handler,this.fail_handler);
	}

	sterge_programare() {
		let url_programari="programari.asp?session-id="+this.props.sess.sessid;
		this.oper="salveaza_stergere_programare";
		var data={
			oper: this.oper,
			id_programare: this.state.selected_el_programare.id,
		};
		fetch_url_post(url_programari,data,this.success_handler,this.fail_handler);
	}

	trimite_sms() {
		this.oper="trimite_sms";
		var data={
			oper: this.oper,
			id_client: this.state.selected_el.id,
		};
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);		
	}

	trimite_email() {
		this.oper="trimite_email";
		var data={
			oper: this.oper,
			id_client: this.state.selected_el.id,
		};
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);		
	}

	message(content) {
		return(
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				open={this.state.message_open}
				autoHideDuration={3000}
				onClose={this.close_message}
				ContentProps={{
					'aria-describedby': 'message-id',
				}}
				message={<span id="message-id">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.message_text}</span>}
				action={[
					<IconButton
						key="close"
						aria-label="Close"
						color="inherit"
						onClick={this.close_message}
					>
					<CloseIcon />
					</IconButton>,
				]}
			/>
		);
	}

	close_message(event,reason) {
		if(reason==="clickaway") {
			return;
		}
		this.setState({
			message_open: false,
			message_text: "",
		});
	}

	operatiuni() {
		if((this.state.selected_el!==undefined)&&(this.state.dupa_modificare===0)) {
			return(
				<React.Fragment>
					<div style={{"textAlign":"center","paddingLeft":"126px"}}>
						<Button onClick={(evt)=>{this.trimite_sms()}} color="primary" variant="contained" style={{"marginTop":"9px","marginRight":"9px"}}>
							<AlarmAddIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
							Trimite SMS
						</Button>
						<Button onClick={(evt)=>{this.trimite_email()}} color="primary" variant="contained" style={{"marginTop":"9px","marginRight":"9px"}}>
							<EditIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
							Trimite email
						</Button>
					</div>
				</React.Fragment>
			);
		}else{
			return(
				<div className="text_center">
					<p>Selectati o persoana din lista pentru operatiuni.</p>
				</div>
			);
		}
	}

	dialog_programari() {
		let titlu="Lista programari ";
		if(this.state.selected_el) {
			titlu+="pentru "+this.state.selected_el.nume+" "+this.state.selected_el.telefon;
		}
		if(this.state.is_loaded_lista_programari===0) {
			return(null);
		}else{
			return(
				<Dialog
					open={this.state.dialog_programari_open}
					onClose={(ev)=>{this.setState({dialog_programari_open: false})}}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					disableEscapeKeyDown={true}
					maxWidth={false}
		  		>
					<DialogTitle id="alert-dialog-title">
						{titlu}
						<Fab color="secondary" size="small" style={{"float":"right"}} onClick={(ev)=>{this.setState({dialog_programari_open: false})}}>
        					<Icon>close_icon</Icon>
						</Fab>
					</DialogTitle>
					<DialogContent style={{"paddingBottom":"33px"}}>
						<Fieldset legend="Lista programari" name="lista_items_2" id="lista_items_2" className="text_center" style={{"padding":"12px","margin":"0px","minHeight":"84%"}}>
							<DataTable ref={(el) => this.dt = el} 
								value={this.state.lista_programari_client}
								scrollable={true}
								paginator={true}
								rows={12}
								selectionMode="single"
								selection={this.state.selected_el_programare}
								onSelectionChange={(e)=>{this.selectare_programare(e)}}
							>
								<Column field="nume_serviciu" header="Serviciu" style={{"width":"18%"}} filter={true} filterMatchMode="contains"/>
								<Column field="nume_maseur" header="Maseur" style={{"width":"12%"}} filter={true} filterMatchMode="contains"/>
								<Column field="durata" header="Durata" style={{"width":"6%"}}/>
								<Column field="data_programarii" header="Data programarii" style={{"width":"15%"}}/>
								<Column field="data_introducerii" header="Data creare" style={{"width":"12%"}}/>							
								<Column field="incasat" header="Incasat" style={{"width":"6%"}} filter={true} filterMatchMode="contains"/>
								<Column field="observatii" header="Observatii" style={{"width":"30%"}} filter={true} filterMatchMode="contains"/>
							</DataTable>
						</Fieldset>
						<Fieldset legend="Operatiuni programare" name="lista_items_2" id="lista_items_2" className="text_center" style={{"padding":"12px","margin":"0px","minHeight":"84%"}}>
							{this.operatiuni_programare()}
						</Fieldset>
        			</DialogContent>
				</Dialog>
			);
		}
	}

	operatiuni_programare() {
		if(this.state.selected_el_programare!==undefined) {
			return(
				<React.Fragment>
					<Button onClick={(evt)=>{this.sterge_programare()}} color="primary" variant="contained" style={{"marginTop":"9px","width":"150px","marginRight":"9px"}}>
						<DeleteOutlineIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
						Stergere
					</Button>
				</React.Fragment>
			);
		}else{
			return(<p>Selectati o programare din tabel pentru operatiuni.</p>);
		}
	}

	render() {
		if(this.state.is_loaded===1) {
			return(
				<div>
					<Fieldset legend="Raport zile nastere" name="lista_items_2" id="lista_items_2" className="text_center" style={{"padding":"12px","margin":"0px","minHeight":"84%"}}>
						<DataTable ref={(el) => this.dt = el} 
							value={this.state.lista}
							scrollable={true}
							paginator={true}
							rows={12}
							selectionMode="single"
							selection={this.state.selected_el}
							onSelectionChange={(e)=>{this.selectare(e)}}
						>
							<Column field="nume" header="Nume" style={{"width":"21%"}} filter={true} filterMatchMode="contains"/>
							<Column field="telefon" header="Telefon" style={{"width":"12%"}} filter={true} filterMatchMode="contains"/>
							<Column field="email" header="Email" style={{"width":"18%"}}/>
							<Column field="observatii" header="Observatii" style={{"width":"36%"}} filter={true} filterMatchMode="contains"/>
							<Column field="data_introducerii" header="Data creare" style={{"width":"12%"}}/>							
						</DataTable>
					</Fieldset>
					<Fieldset legend="Operatiuni" className="cautare_client" style={{"padding":"12px","margin":"0px","minHeight":"15%"}}>
						{this.operatiuni()}
					</Fieldset>
					{this.message()}
				</div>
			)			
		}else{
			return(<LinearProgress/>);
		}
	}
}

export default RaportZiNastere;
