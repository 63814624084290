import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Fieldset} from 'primereact/fieldset';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider,DatePicker} from 'material-ui-pickers';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {fetch_url_post,add_to_array,remove_from_array,find_index_in_array,replace_element_in_array,clone_object} from "../../Sources/js/app.js";

class RaportIncasari extends React.Component {
	constructor(props) {
		super(props);
		this.data=new Date().toISOString().slice(0,10).replace('T', ' ');
		let tmp_data=this.data.split("-");
		this.data=tmp_data[2]+"-"+tmp_data[1]+"-"+tmp_data[0];
		this.reset_data={
			id: 0,
			data_start: this.data,
			data_stop: this.data,
		};
		this.state={
			is_loaded: 1,
			lista: [],
            message_open: false,
			message_text: "",
			data:clone_object(this.reset_data),
        };
		this.oper="";
		this.modif_el={};		
		this.url="rapoarte.asp?session-id="+this.props.sess.sessid;
		this.success_handler=this.success_handler.bind(this);
		this.fail_handler=this.fail_handler.bind(this);
		this.updateInputValue=this.updateInputValue.bind(this);
		this.message=this.message.bind(this);	
		this.close_message=this.close_message.bind(this);
		this.operatiuni=this.operatiuni.bind(this);
		this.reseteaza=this.reseteaza.bind(this);
		this.updateInputValueData=this.updateInputValueData.bind(this);
		this.genereaza_raport=this.genereaza_raport.bind(this);
	}
		
	success_handler(result) {
		if(this.oper==="raport_incasari") {
			this.oper="";
			this.setState({
				is_loaded: 1,
				lista: result.data.lista,
			});
		}
	}

	fail_handler() {
		this.setState({
			result: null,
			is_loaded: 1,
			message_open: true,
			message_text: "A intervenit o eroare de comunicatie! Va rugam sa incercati mai tarziu!",
		});
	}

    componentDidMount() {
		return false;
	}
	
	updateInputValue(evt) {
		let tmp=this.state.data;
		tmp[evt.target.name]=evt.target.value;
		this.setState({
			data: tmp,
		});
	}

	updateInputValueData(date,type) {
		let year=date.getFullYear();
		let month=date.getMonth()+1;
		let day=date.getDate();
		if(month<10) {
			month="0"+month;
		}
		if(day<10) {
			day="0"+day;
		}
		let tmp=this.state.data;
		if(type==="start") {
			tmp.data_start=year+"-"+month+"-"+day;
		}else{
			tmp.data_stop=year+"-"+month+"-"+day;			
		}
		this.setState({
			data: tmp,
		});
	}

	reseteaza() {
		this.setState({
			selected_el: undefined,
			data: clone_object(this.reset_data),
		});
	}

	genereaza_raport() {
		this.oper="raport_incasari";
		var data={
			oper: this.oper,
			data: this.state.data,
		};
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);
	}

	message(content) {
		return(
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				open={this.state.message_open}
				autoHideDuration={3000}
				onClose={this.close_message}
				ContentProps={{
					'aria-describedby': 'message-id',
				}}
				message={<span id="message-id">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.message_text}</span>}
				action={[
					<IconButton
						key="close"
						aria-label="Close"
						color="inherit"
						onClick={this.close_message}
					>
					<CloseIcon />
					</IconButton>,
				]}
			/>
		);
	}

	close_message(event,reason) {
		if(reason==="clickaway") {
			return;
		}
		this.setState({
			message_open: false,
			message_text: "",
		});
	}

	operatiuni() {
		return(
			<div className="text_center">
				<Button onClick={(ev)=>{this.reseteaza()}} color="primary" variant="contained" style={{"marginTop":"9px","marginRight":"9px"}}>
					<EditIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
					Exporta ca xls
				</Button>
			</div>
		);
	}

	render() {
		if(this.state.is_loaded===1) {
			return(
				<div>
					<Fieldset legend="Setari raport incasari" name="lista_items_1" id="lista_items_1" className="text_center" style={{"padding":"12px","margin":"0px","minHeight":"84%"}}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<DatePicker
								name="data_start"  
								id="data_start"
								clearable
								variant="outlined"
								margin="dense"
								format="dd-MM-yyyy" 
								label="Data start"
								style={{"width":"144px","marginRight":"120px"}}
								value={this.state.data.data_start}
					  			onChange={date => this.updateInputValueData(date,"start")}
							/>
						</MuiPickersUtilsProvider>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<DatePicker
								name="data_stop"  
								id="data_stop"
								clearable
								variant="outlined"
								margin="dense"
								format="dd-MM-yyyy" 
								label="Data" stop
								style={{"width":"144px","marginRight":"300px"}}
								value={this.state.data.data_stop}
					  			onChange={date => this.updateInputValueData(date,"stop")}
							/>
						</MuiPickersUtilsProvider>
						<Button onClick={(ev)=>{this.genereaza_raport()}} color="primary" variant="contained" style={{"marginTop":"9px"}}>
							<RefreshIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
							Gerereaza raport
						</Button>
					</Fieldset>
					<Fieldset legend="Raport incasari" name="lista_items_1" id="lista_items_1" className="text_center" style={{"padding":"12px","margin":"0px","minHeight":"84%"}}>
						<DataTable ref={(el) => this.dt = el} 
							value={this.state.lista}
							scrollable={true}
							paginator={true}
							rows={15}
							selectionMode="single"
							selection={this.state.selected_el}
							onSelectionChange={(e)=>{this.selectare(e)}}
						>
							<Column field="nume_client" header="Nume client" style={{"width":"18%"}} filter={true} filterMatchMode="contains"/>
							<Column field="nume_serviciu" header="Serviciu" style={{"width":"30%"}} filter={true} filterMatchMode="contains"/>
							<Column field="nume_maseur" header="Maseur" style={{"width":"24%"}} filter={true} filterMatchMode="contains"/>
							<Column field="durata" header="Durata" style={{"width":"12%"}}/>
							<Column field="data_programarii" header="Data programarii" style={{"width":"15%"}}/>						
						</DataTable>
					</Fieldset>
					<Fieldset legend="Operatiuni raport incasari" className="cautare_client" style={{"padding":"12px","margin":"0px","minHeight":"15%"}}>
						{this.operatiuni()}
					</Fieldset>
					{this.message()}
				</div>
			)			
		}else{
			return(<LinearProgress/>);
		}
	}
}

export default RaportIncasari;
