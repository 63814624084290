import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Fieldset} from 'primereact/fieldset';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {fetch_url_post,add_to_array,remove_from_array,find_index_in_array,replace_element_in_array,clone_object} from "../../Sources/js/app.js";

class Servicii extends React.Component {
	constructor(props) {
		super(props);
		this.data=new Date().toISOString().slice(0,10).replace('T', ' ');
		let tmp_data=this.data.split("-");
		this.data=tmp_data[2]+"-"+tmp_data[1]+"-"+tmp_data[0];
		this.reset_data={
			id: 0,
			nume: "",
			descriere: "",
			activ: "1",
			durata: "10",
			pret: 0,
			data_introducerii: this.data,
		}
		this.state={
            is_loaded: 0,
			lista: [],
			message_open: false,
			message_text: "",
			selected_el: undefined,
			boolean_list: [
				{
					label: "DA",
					value: "1",
				},
				{
					label: "NU",
					value: "0",
				},				
			],
			durata_list: [
				{
					label: "10 minute",
					value: "10",
				},
				{
					label: "15 minute",
					value: "15",
				},
				{
					label: "20 de minute",
					value: "20",
				},
				{
					label: "25 de minute",
					value: "25",
				},
				{
					label: "30 de minute",
					value: "30",
				},
				{
					label: "35 de minute",
					value: "35",
				},
				{
					label: "40 de minute",
					value: "40",
				},
				{
					label: "45 de minute",
					value: "45",
				},
				{
					label: "50 de minute",
					value: "50",
				},
				{
					label: "55 de minute",
					value: "55",
				},
				{
					label: "60 de minute",
					value: "60",
				},				
			],
			data:clone_object(this.reset_data),
			dupa_modificare: 0,
        };
        this.oper="";
		this.modif_el={};
		this.url="servicii.asp?session-id="+this.props.sess.sessid;
		this.success_handler=this.success_handler.bind(this);
		this.fail_handler=this.fail_handler.bind(this);
		this.updateInputValue=this.updateInputValue.bind(this);
		this.message=this.message.bind(this);	
		this.close_message=this.close_message.bind(this);
		this.selectare=this.selectare.bind(this);
		this.operatiuni=this.operatiuni.bind(this);
		this.salveaza_date=this.salveaza_date.bind(this);
		this.sterge_date=this.sterge_date.bind(this);
		this.reseteaza=this.reseteaza.bind(this);
	}
		
	success_handler(result) {
		if(this.oper==="lista") {
			this.oper="";
			this.setState({
				is_loaded: 1,
				lista: result.data.lista,
			});
		}
		if(this.oper==="salveaza_adaugare") {
			this.oper="";
			if(result.ok===1) {
				let new_item=this.state.data;
				new_item.id=result.new_id;
				this.setState({
					is_loaded: 1,
					new_id: result.new_id,
					lista: add_to_array(this.state.lista,new_item),
					data: clone_object(this.reset_data),
					message_open: true,
					message_text: "Serviciul a fost adaugat cu succes!",
				});
			}else{
				this.setState({
					is_loaded: 1,
					message_open: true,
					message_text: "A intervenit o eroare in comunicatia cu server-ul. Va rugam sa reincercati mai tarziu!",
				});
			}
		}
		if(this.oper==="salveaza_modificare") {
			this.oper="";
			if(result.ok===1) {
				this.setState({
					is_loaded: 1,
					lista: replace_element_in_array(this.state.lista,this.state.selected_el,this.state.data),
					data: clone_object(this.reset_data),
					dupa_modificare: 1,
					selected_el: this.state.lista[find_index_in_array(this.state.lista,this.state.selected_el)],
					message_open: true,
					message_text: "Serviciul a fost modificat cu succes!",
				});
			}else{
				this.setState({
					is_loaded: 1,
					message_open: true,
					message_text: "A intervenit o eroare in comunicatia cu server-ul. Va rugam sa reincercati mai tarziu!",
				});
			}
		}
		if(this.oper==="salveaza_stergere") {
			this.oper="";
			if(result.ok===1) {
				this.setState({
					is_loaded: 1,
					data: clone_object(this.reset_data),
					lista: remove_from_array(this.state.lista,this.state.selected_el),
					selected_el: undefined,
					message_open: true,
					message_text: "Serviciul a fost sters cu succes!",
				});
			}else{
				this.setState({
					is_loaded: 1,
					message_open: true,
					message_text: "A intervenit o eroare in comunicatia cu server-ul. Va rugam sa reincercati mai tarziu!",
				});
			}
		}
	}

	fail_handler() {
		this.setState({
			result: null,
			is_loaded: 1,
			message_open: true,
			message_text: "A intervenit o eroare de comunicatie! Va rugam sa incercati mai tarziu!",
		});
	}

    componentDidMount() {
		this.oper="lista";
		var data={
			oper: this.oper,
		};
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);
	}
	
	updateInputValue(evt) {
		let tmp=this.state.data;
		if(evt.target.name==="activ") {
			if(evt.target.value==="1") {
				tmp["activ_nume"]="DA";
			}else{
				tmp["activ_nume"]="NU";
			}
		}
		tmp[evt.target.name]=evt.target.value.toUpperCase();
		this.setState({
			data: tmp,
		});
	}

    salveaza_date(tip) {
		if(this.state.data.nume==="") {
			this.setState({
				message_open: true,
				message_text: "Completati campul nume serviciu!",				
			});
			return false;
		}
		if(tip==="adaugare") {
			this.oper="salveaza_adaugare";
		}else{
			this.oper="salveaza_modificare";
		}
		var data={
			oper: this.oper,
			data: this.state.data,
		};
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);
	}

	selectare(evt) {
		this.modif_el=clone_object(evt.value);
		this.setState({
			data: this.modif_el,
			selected_el: evt.value,
			dupa_modificare: 0,
		});
	}

	sterge_date() {
		if(window.confirm("Sigur doriti stergerea? Operatiunea este ireversibila!")) {
			this.oper="salveaza_stergere";
			var data={
				oper: this.oper,
				data: this.state.data,
			};
			fetch_url_post(this.url,data,this.success_handler,this.fail_handler);		
		}
	}

	reseteaza() {
		this.setState({
			selected_el: undefined,
			data: clone_object(this.reset_data),
		});
	}

	message(content) {
		return(
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				open={this.state.message_open}
				autoHideDuration={3000}
				onClose={this.close_message}
				ContentProps={{
					'aria-describedby': 'message-id',
				}}
				message={<span id="message-id">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.message_text}</span>}
				action={[
					<IconButton
						key="close"
						aria-label="Close"
						color="inherit"
						onClick={this.close_message}
					>
					<CloseIcon />
					</IconButton>,
				]}
			/>
		);
	}

	close_message(event,reason) {
		if(reason==="clickaway") {
			return;
		}
		this.setState({
			message_open: false,
			message_text: "",
		});
	}

	operatiuni() {
		if((this.state.selected_el!==undefined)&&(this.state.dupa_modificare===0)) {
			return(
				<React.Fragment>
					<Grid container spacing={1}>
						<Grid item xs={4}>
							<TextField
								required
								variant="outlined"
								margin="dense"
								name="nume"
								id="nume"
								label="Nume"
								fullWidth
								autoComplete="nume"
								value={this.state.data.nume}
								onChange={evt => this.updateInputValue(evt)}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								required
								variant="outlined"
								margin="dense"
								name="descriere"
								id="descriere"
								label="Descriere"
								fullWidth
								autoComplete="descriere"
								value={this.state.data.descriere}
								onChange={evt => this.updateInputValue(evt)}
							/>
						</Grid>
						<Grid item xs={2}>
							<TextField
								variant="outlined"
								margin="dense"
								name="durata"
								id="durata"
								select
								label="Durata"
								fullWidth
								SelectProps={{
									native: true,
								}}
								value={this.state.data.durata}
								onChange={evt => this.updateInputValue(evt)}
							>
								{this.state.durata_list.map(option => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</TextField>
						</Grid>
						<Grid item xs={1}>
							<TextField
								required
								variant="outlined"
								margin="dense"
								name="pret"
								id="pret"
								label="Pret"
								fullWidth
								autoComplete="pret"
								value={this.state.data.pret}
								onChange={evt => this.updateInputValue(evt)}
							/>
						</Grid>
						<Grid item xs={1}>
							<TextField
								variant="outlined"
								margin="dense"
								name="activ"
								id="activ"
								select
								label="Activ"
								fullWidth
								SelectProps={{
									native: true,
								}}
								value={this.state.data.activ}
								onChange={evt => this.updateInputValue(evt)}
							>
								{this.state.boolean_list.map(option => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</TextField>
						</Grid>
					</Grid>				
					<div style={{"textAlign":"center","paddingLeft":"126px"}}>
						<Button onClick={(evt)=>{this.salveaza_date("modificare")}} color="primary" variant="contained" style={{"marginTop":"9px","width":"150px","marginRight":"9px"}}>
							<EditIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
							Modificare
						</Button>
						<Button onClick={(evt)=>{this.sterge_date()}} color="primary" variant="contained" style={{"marginTop":"9px","width":"150px"}}>
							<DeleteOutlineIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
							Stergere
						</Button>
						<Button onClick={(evt)=>{this.reseteaza()}} color="primary" variant="contained" style={{"marginTop":"9px","width":"150px","marginRight":"9px","float":"right"}}>
							<RefreshIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
							Reseteaza
						</Button>
					</div>
				</React.Fragment>
			);
		}else{
			return(
				<React.Fragment>
					<Grid container spacing={1}>
						<Grid item xs={4}>
							<TextField
								required
								variant="outlined"
								margin="dense"
								name="nume"
								id="nume"
								label="Nume"
								fullWidth
								autoComplete="nume"
								value={this.state.data.nume}
								onChange={evt => this.updateInputValue(evt)}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								required
								variant="outlined"
								margin="dense"
								name="descriere"
								id="descriere"
								label="Descriere"
								fullWidth
								autoComplete="descriere"
								value={this.state.data.descriere}
								onChange={evt => this.updateInputValue(evt)}
							/>
						</Grid>
						<Grid item xs={2}>
							<TextField
								variant="outlined"
								margin="dense"
								name="durata"
								id="durata"
								select
								label="Durata"
								fullWidth
								SelectProps={{
									native: true,
								}}
								value={this.state.data.durata}
								onChange={evt => this.updateInputValue(evt)}
							>
								{this.state.durata_list.map(option => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</TextField>
						</Grid>
						<Grid item xs={1}>
							<TextField
								required
								variant="outlined"
								margin="dense"
								name="pret"
								id="pret"
								label="Pret"
								fullWidth
								autoComplete="pret"
								value={this.state.data.pret}
								onChange={evt => this.updateInputValue(evt)}
							/>
						</Grid>
						<Grid item xs={1}>
							<TextField
								variant="outlined"
								margin="dense"
								name="activ"
								id="activ"
								select
								label="Activ"
								fullWidth
								SelectProps={{
									native: true,
								}}
								value={this.state.data.activ}
								onChange={evt => this.updateInputValue(evt)}
							>
								{this.state.boolean_list.map(option => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</TextField>
						</Grid>
					</Grid>								
					<div style={{"textAlign":"center"}}>
						<Button onClick={(ev)=>{this.salveaza_date("adaugare")}} color="primary" variant="contained" style={{"marginTop":"9px","width":"150px"}}>
							<AddCircleOutlineIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
							Adaugare
						</Button>
					</div>
				</React.Fragment>
			);
		}
	}

	render() {
		if(this.state.is_loaded===1) {
			return(
				<div>
					<Fieldset legend="Lista servicii" name="lista_items_1" id="lista_items_1" className="text_center" style={{"padding":"12px","margin":"0px","minHeight":"84%"}}>
						<DataTable ref={(el) => this.dt = el} 
							value={this.state.lista}
							scrollable={true}
							paginator={true}
							rows={15}
							selectionMode="single"
							selection={this.state.selected_el}
							onSelectionChange={(e)=>{this.selectare(e)}}
						>
							<Column field="nume" header="Nume" style={{"width":"24%"}} filter={true} filterMatchMode="contains"/>
							<Column field="descriere" header="Descriere" style={{"width":"24%"}} filter={true} filterMatchMode="contains"/>
							<Column field="durata" header="Durata (minute)" style={{"width":"15%"}}/>
							<Column field="pret" header="Pret" style={{"width":"12%"}}/>
							<Column field="activ_nume" header="Activ" style={{"width":"12%"}}/>
							<Column field="data_introducerii" header="Data creare" style={{"width":"12%"}}/>							
						</DataTable>
					</Fieldset>
					<Fieldset legend="Operatiuni servicii" className="cautare_client" style={{"padding":"12px","margin":"0px","minHeight":"15%"}}>
						{this.operatiuni()}
					</Fieldset>
					{this.message()}
				</div>
			)			
		}else{
			return(<LinearProgress/>);
		}
	}
}

export default Servicii;
